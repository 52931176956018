// import s from "./GradeSession.module.css";
// import { useState, useEffect } from 'react';
// import { Layout } from "../../layouts/Layout/Layout";
// import { Container } from "../../layouts/Container/Container";
// import { Link, useParams } from 'react-router-dom'
// import { Button } from '../../ui/Button/Button';
// import { CardTask } from "../../ui/CardTask/CardTask";

// export const GradeSession = () => {
//     // const { idGradeTest } = useParams();
//     const gradeID = 1;
//     const [grade, getGrade] = useState([]);
//     const { idCaseTest } = useParams();
//     const caseID = 1;
//     // const [case, getCase] = useState([]);

//     //получить данные по курсу
//     useEffect(() => {
//         const requestOptions = {
//           method: "GET",
//           headers: new Headers({
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           }),
//           redirect: "follow",
//         };

//         const fetchData = async () => {
//           try {
//             const response = await fetch(
//               `${process.env.REACT_APP_API_URL}/assessment-testing/${gradeID}/assessment_testing`,
//               requestOptions
//             );
//             if (!response.ok) {
//               throw new Error("Network response was not ok");
//             }
//             const data = await response.json();
//             getGrade(data);
//           } catch (error) {
//             console.error(error);
//           }
//         };
//         fetchData();
//       }, [gradeID]);

//   return (
//     <Layout>
//       <Container>
//         <section className={s.gradeSession}>
//             <Link to="/my_сourses">
//                 <Button text='← Назад' type='back'/>
//             </Link>
//             <h1 className={s.title}>Оценочная сессия</h1>
//             <p className={s.descr}>Проведение учебного занятия по учебным предметам, курсам, дисциплинам (модулям) с применением ЭО и ДОТ</p>
//             <div className={s.content}>
//                 <Link to={`/grade_page/${gradeID}`}>
//                     <CardTask
//                         task='Тестовая часть'
//                         text='Описание'
//                         />
//                 </Link>
//                 <Link to={`/case_page/${caseID}`}>
//                     <CardTask
//                         task='Кейсы'
//                         text='Описание'/>
//                 </Link>
//             </div>
//             {console.log(grade)}
//         </section>
//       </Container>
//     </Layout>
//   );
// };

// import s from "./GradeSession.module.css";
// import { useState, useEffect } from "react";
// import { Layout } from "../../layouts/Layout/Layout";
// import { Container } from "../../layouts/Container/Container";
// import { Link, useParams } from "react-router-dom";
// import { Button } from "../../ui/Button/Button";
// import { CardTask } from "../../ui/CardTask/CardTask";

// export const GradeSession = () => {
//   const [grade, getGrade] = useState([]);
//   const [session, getSession] = useState([]);
//   const { id } = useParams();

//   //получить данные по курсу
//   useEffect(() => {
//     const requestOptions = {
//       method: "GET",
//       headers: new Headers({
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       }),
//       redirect: "follow",
//     };

//     const fetchData = async () => {
//       // console.log(grade);
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-testing/${id}/assessment_testing`,
//           requestOptions
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
//         getGrade(data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchData();
//   }, [id]);

//   useEffect(() => {
//     const getSessionData = async () => {
//       const requestOptions = {
//         method: "GET",
//         headers: new Headers({
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         }),
//         redirect: "follow",
//       };

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-tests/test/${id}`,
//           requestOptions
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
//         // console.log(data);
//         getSession(data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     getSessionData();
//   }, [id]);

//   console.log(session);

//   return (
//     <Layout>
//       <Container>
//         <section className={s.gradeSession}>
//           <Link to="/my_сourses">
//             <Button text="← Назад" type="back" />
//           </Link>
//           <h1 className={s.title}>Оценочная сессия</h1>
//           <p className={s.descr}>{session?.sphere?.name}</p>
//           <div className={s.content}>
//             <Link to={`/grade_page/${id}`}>
//               <CardTask task="Тестовая часть" text="Описание" />
//             </Link>
//             <Link to={`/case_page/${id}`}>
//               <CardTask task="Кейсы" text="Описание" />
//             </Link>
//           </div>
//           {/* {console.log(grade)} */}
//         </section>
//       </Container>
//     </Layout>
//   );
// };

import s from "./GradeSession.module.css";
import { useState, useEffect } from "react";
import { Layout } from "../../layouts/Layout/Layout";
import { Container } from "../../layouts/Container/Container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../ui/Button/Button";
import { CardTask } from "../../ui/CardTask/CardTask";

export const GradeSession = () => {
  const [grade, setGrade] = useState([]);
  const [session, setSession] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const previousLocation = location.state?.from || "/my_courses";

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      redirect: "follow",
    };

    const fetchGradeData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-testing/${id}/assessment_testing`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setGrade(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSessionData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/test/${id}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSession(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGradeData();
    fetchSessionData();
  }, [id]);

  const isTestDisabled = Array.isArray(session.assessment_testings)
    ? session.assessment_testings.some(
        (test) => test.type === "assessment_testing" && test.totalCount > 0
      )
    : false;

  const isCaseDisabled = Array.isArray(session.assessment_testings)
    ? session.assessment_testings.some(
        (test) => test.type === "case_testing" && test.totalCount > 0
      )
    : false;

  return (
    <Layout>
      <Container >
        <section className={s.gradeSession}>
          <Button
            text="← Назад"
            type="back"
            onClick={() => navigate(previousLocation)}
          />

          <h1 className={s.title}>Оценочная сессия</h1>
          <p className={s.descr}>{session?.sphere?.name}</p>
          <div className={s.content}>
            {!isTestDisabled ? (
              <Link
                to={`/grade_page/${id}`}
                state={{
                  from: previousLocation,
                  sphereId: session?.sphere_id,
                }}
              >
                <CardTask
                  task="Тестовая часть"
                  text={session?.sphere?.description}
                  isLoading={isLoading}
                  test
                />
              </Link>
            ) : (
              <CardTask
                task="Тестовая часть"
                complete
                text="Описание"
                isLoading={isLoading}
              />
            )}
            {!isCaseDisabled ? (
              <Link
                to={`/case_page/${id}`}
                state={{
                  from: previousLocation,
                  sphereId: session?.sphere_id,
                }}
              >
                <CardTask
                  task="Кейсы"
                  text="Описание"
                  isLoading={isLoading}
                  test
                />
              </Link>
            ) : (
              <CardTask
                task="Кейсы"
                complete
                text={session?.sphere?.description}
                isLoading={isLoading}
              />
            )}
          </div>
        </section>
      </Container>
    </Layout>
  );
};
