import { Layout } from "../../layouts/Layout/Layout";
import clsx from "clsx";
import s from "./CompetencePage.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { Container } from "../../layouts/Container";
import { Title } from "../../ui/Title/Title";
import highlevel from "../../assets/icons/highlevel.svg";
import normallevel from "../../assets/icons/normallevel.svg";
import lowlevel from "../../assets/icons/lowlevel.svg";
import { Button } from "../../ui/Button/Button";
import { Card } from "../../components/Card/Card";
import { formatToRussianDate } from "../../helpers/formatToRussianDate";
import { getLevelDescription } from "../../helpers/getLevelDescription";
import { getLevelCategory } from "../../helpers/getLevelCategory";
import { getBarStyle } from "../../helpers/getBarStyle";
import { Spinner } from "../../ui/Spinner/Spinner";

export const CompetencePage = () => {
  const navigate = useNavigate();
  const { sphereId } = useParams();
  const [sphereResults, setSphereResults] = useState(null);
  const [finishedSphere, setFinishedSphere] = useState(false);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSphereData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/result/sphere/${sphereId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.length > 0) {
          setSphereResults(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSphereData();
  }, [sphereId]);

  useEffect(() => {
    const fetchFinishedCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/results`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        const sphereResult = data.filter(
          (result) => result.sphere.id == sphereId
        );

        setFinishedSphere(sphereResult.length > 0);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFinishedCourses();
  }, [sphereId]);

  useEffect(() => {
    const fetchAssignedCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        if (data && data.length > 0) {
          const relevantCourses = data.filter((course) =>
            course.lecture.spheres.some(
              (sphere) => sphere.id === parseInt(sphereId)
            )
          );

          const incompleteCourses = relevantCourses.filter(
            (course) => course.completed_lessons < course.lecture.lessons_count
          );

          setAssignedCourses(incompleteCourses);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAssignedCourses();
  }, [sphereResults, sphereId, finishedSphere]);

  const formattedResults = useMemo(() => {
    return sphereResults?.map((result) => ({
      ...result,
      formattedDate: formatToRussianDate(result.updated_at),
      levelCategory: getLevelCategory(result.level),
    }));
  }, [sphereResults]);

  const latestResult = formattedResults?.[formattedResults.length - 1];

  return (
    <Layout>
      <Container>
        <div className={s.wrapper}>
          {isLoading ? (
            <Spinner className={s.spinner} />
          ) : latestResult ? (
            <>
              <Button text="← Назад" type="back" onClick={() => navigate(-1)} />

              <Title>{latestResult?.sphere?.name}</Title>

              <div className={s.level__rang}>
                <div className={s.level__wrapper}>
                  <img
                    className={s.level__icon}
                    src={latestResult?.levelCategory.icon}
                    alt="level"
                  />
                  <p
                    className={clsx(
                      s.level__text,
                      latestResult?.levelCategory.className
                    )}
                  >
                    {latestResult?.levelCategory.text}
                  </p>
                </div>

                <p
                  className={clsx(
                    s.level__desc,
                    latestResult?.levelCategory.className
                  )}
                >
                  {getLevelDescription(latestResult?.levelCategory.text)}
                </p>
              </div>

              <div className={s.level__rating__wrapper}>
                {formattedResults?.map((result, index, arr) => (
                  <div key={index} className={s.level__rating__card}>
                    <div
                      className={s.level__rating}
                      style={getBarStyle(
                        result.levelCategory.text,
                        index === arr.length - 1
                      )}
                    />
                    <p
                      className={clsx(
                        s.date,
                        index === arr.length - 1 && s.date__last
                      )}
                    >
                      {result.formattedDate}
                    </p>
                  </div>
                ))}
              </div>

              {/* НАЗНАЧЕННЫЕ КУРСЫ */}
              {finishedSphere &&
                latestResult?.levelCategory?.text === "Дефицитарный уровень" &&
                assignedCourses.length > 0 && (
                  <div className={s.courses}>
                    <h2 className={s.courses__title}>Назначенные курсы</h2>
                    <div className={s.courses__wrapper}>
                      {assignedCourses.map((course, index) => (
                        <Link
                          to={`/my_courses/page_course/${course.lecture.id}`}
                          key={index}
                        >
                          <Card
                            img={course.lecture.image}
                            alt="Картинка"
                            title={course.lecture.title}
                            chipText={course.lecture.tag || undefined}
                            chip="green"
                            descr={course.lecture.description}
                            buttonType="round"
                            buttonText=""
                            id={course.lecture.id}
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
            </>
          ) : (
            <h2 className={s.courses__title}>Результаты не найдены!</h2>
          )}
        </div>
      </Container>
    </Layout>
  );
};
